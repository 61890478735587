import React from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import {
  feed__item,
  feed__itemMeta,
  feed__itemMetaTime,
  feed__itemMetaDivider,
  feed__itemMetaCategoryLink,
  feed__itemTitle,
  feed__itemTitleLink,
  feed__itemDescription,
  feed__itemReadmore,
} from './Feed.module.scss';

const Feed = ({ edges }) => (
  <div className>
    {edges.map((edge) => (
      <div className={feed__item} key={edge.node.fields.slug}>
        <div className={feed__itemMeta}>
          <time
            className={feed__itemMetaTime}
            dateTime={moment(edge.node.frontmatter.date).format('MMMM D, YYYY')}
          >
            {moment(edge.node.frontmatter.date).format('MMMM YYYY')}
          </time>
          <span className={feed__itemMetaDivider} />
          <span>
            <Link
              to={edge.node.fields.categorySlug}
              className={feed__itemMetaCategoryLink}
            >
              {edge.node.frontmatter.category}
            </Link>
          </span>
        </div>
        <h2 className={feed__itemTitle}>
          <Link className={feed__itemTitleLink} to={edge.node.fields.slug}>
            {edge.node.frontmatter.title}
          </Link>
        </h2>
        <p className={feed__itemDescription}>
          {edge.node.frontmatter.description}
        </p>
        <Link className={feed__itemReadmore} to={edge.node.fields.slug}>
          Read
        </Link>
      </div>
    ))}
  </div>
);

export default Feed;
